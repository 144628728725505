<template lang="pug">
.page
  .section
    .title main
    .content
      el-button(@click="handleMessage('main')") message
  .section
    .title sub
    .content
      el-button(@click="handleMessage('sub')") message
  .section
    .title router list
    .content {{ routerList }}
    .content 
      el-button(type="primary" @click="submitRouterList") submitRouterList
</template>

<script setup>
import { inject, onMounted, ref, computed } from 'vue'
import { routes } from '@/plugin/router'

const { fetch, message } = inject('global')
const routerList = computed(() => routes)

function handlePing() {
  fetch.get('/homebrew-api/dev/ping')
}

function handleMessage(type) {
  if (type === 'main') {
    window.$globalProp.component.message.success("来自主项目的组件")
  } else {
    message.success("来自子项目的组件")
  }
}

function submitRouterList() {
  fetch.post('/homebrew-api/webPage/project/tiktok/submit', {
    routerList: routerList.value
  })
}
</script>

<style lang="less" scoped>
.section {
  --gutter: 20px;
  border: 1px solid var(--color-primary);
  margin: var(--gutter);
  border-radius: var(--gutter);
  overflow: hidden;
  .title {
    text-align: center;
    padding: 10px;
    background: var(--color-primary);
    color: white;
    font-size: 20px;
  }
  .content {
    padding: var(--gutter);
  }
}
</style>
